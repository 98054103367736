<div class="container cart-checkout-page">
    <div class="content-checkout">
        <div class="loading-container" *ngIf="!loaded || waiting">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>

        <div class="list-products-cart item-checkout">
            <div class="flex-continue-back" (click)="goBack()">
                <i class="fa-light fa-arrow-left"></i>
                <p class="m-0">Continuar comprando</p>
            </div>
            <p class="title-item-checkout">Carrito de compra</p>
            <div class="time-checkout" *ngIf="shoppingCartUser?.length <= 0 && loaded">
                <p class="m-0">
                    <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Aún no has añadido
                    ninguna entrada.
                </p>
            </div>

            <div class="time-checkout" *ngIf="shoppingCartUser?.length > 0">
                <p class="m-0"><i class="fa-light fa-clock icon-primary" style="margin-right: 8px;"></i>Te quedan <span
                        class="text-time">
                        <span class="number" *ngIf="countdown.days<0">00</span>
                        <span class="number" *ngIf="countdown.days>=0">{{countdown.minutes|number:'2.0-2'}}</span>
                        <span>:</span>
                        <span class="number" *ngIf="countdown.days<0">00</span>
                        <span class="number" *ngIf="countdown.days>=0">{{countdown.seconds|number:'2.0-2'}}</span>
                    </span> para completar el pedido, o las entradas elegidas se
                    perderán.</p>
            </div>
            <div class="booking-list">
                <div *ngFor="let event of shoppingCartUser">
                    <div class="item-booking">
                        <div class="img-event">
                            <img fallimg class="img-event" [src]="urlImages+event.id+'/'+event.imageurl" />
                        </div>
                        <div class="info-event">
                            <div class="number-event d-flex align-items-start justify-content-between"
                                style="gap: 12px;">
                                <div class="d-flex title-event">
                                    <span style="min-width: fit-content;">{{event.tickets}} x</span>
                                    <span class="title-link"
                                        (click)="openEvent(event.id,event.titulo)">{{event.titulo}}</span>
                                </div>
                                <p class="total-price-event title-event m-0" style="min-width: fit-content;">
                                    {{event.price | number: '1.2-2'}} €</p>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="event.discount">
                                <span>Descuento aplicado: </span>
                                <span class="style-discount">-{{ event.discount | number:'1.2-2' }} €</span>
                            </div>
                            <div class="d-flex owner-event">
                                <span class="span-author">Creado por: </span>
                                <span class="title-author">{{ event.company?.name }}</span>
                            </div>
                            <div class="locate-event d-flex align-items-center">
                                <i class="fa-light fa-map-marker-alt icon-primary"></i>
                                <p class="m-0">{{event.localizacion}}</p>
                            </div>
                            <div class="d-flex justify-content-between align-items-end">
                                <div class="show-detail-event d-flex align-items-center pointer-in"
                                    (click)="showDetailTickets(event.id)">
                                    <i class="fa-light icon-primary"
                                        [class]="openDetail == event.id ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                                    <p class="m-0">{{openDetail == event.id ? 'Ocultar' : 'Mostrar'}} detalles</p>
                                </div>
                                <div class="show-detail-event">
                                    <p class="m-0 delete-items" (click)="showDetailTickets(event.id)">Modificar entradas
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="detail-tickets" *ngIf="openDetail == event.id">
                        <div *ngFor="let option of event.options">
                            <div class="d-flex align-items-center" style="gap: 150px;">
                                <div class="date-option d-flex align-items-center">
                                    <i class="fa-light fa-clock icon-primary"></i>
                                    <p class="m-0">{{option.date | date:'EEEE, dd MMM yyyy' | titlecase}}</p>
                                    <span *ngIf="option.time">-</span>
                                    <p class="m-0" *ngIf="option.time">{{option.time}}</p>
                                </div>
                            </div>
                            <div *ngFor="let item of option.tickets" class="info-tickets">
                                <div class="d-flex align-items-center justify-content-between" style="gap: 10px;">
                                    <p class="m-0 title-ticket">{{item.ticket.nombre}}</p>
                                    <div class="d-flex align-items-center" style="gap: 10px; min-width: fit-content;">
                                        <p class="m-0">{{(item.originalPrice||item.price) | number: '1.2-2'}} €</p>
                                        <i class="fa-light fa-trash-can icon-primary pointer-in"
                                            (click)="deleteItemCart(item)"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between" *ngIf="item.discount">
                                    <p class="m-0">Descuento aplicado: {{item.discountName}}</p>
                                    <p style="margin: 0px 16px; min-width: fit-content;" class="style-discount">
                                        -{{item.discount | number: '1.2-2'}} €</p>
                                </div>
                                <div class="m-0" style="gap: 8px;"><span *ngIf="item.section">Sección:
                                        {{item.sectionName}},</span> <span *ngIf="item.row && item.showRow"> Fila:
                                        {{item.row}},</span>
                                    <span *ngIf="item.seat"> Asiento: {{item.seat}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </div>
        </div>
        <div class="resume-checkout item-checkout" *ngIf="shoppingCartUser?.length > 0">
            <p class="title-item-checkout">Resumen</p>
            <div *ngFor="let event of shoppingCartUser">
                <div *ngFor="let option of event.options">
                    <div class="number-event d-flex align-items-center justify-content-between">
                        <div class="d-flex title-event" style="gap: 10px;">
                            <span>{{option.tickets.length}} x</span>
                            <span>{{event.titulo}}</span>
                        </div>
                        <p class="total-price-event title-event">{{option.priceDate | number: '1.2-2'}} €</p>
                    </div>
                    <div class="detail-resume">
                        <div class="locate-event d-flex align-items-center">
                            <i class="fa-light fa-map-marker-alt icon-primary"></i>
                            <p class="m-0">{{event.localizacion}}</p>
                        </div>
                        <div class="date-option d-flex align-items-center">
                            <i class="fa-light fa-clock icon-primary"></i>
                            <p class="m-0">{{option.date | date:'EEEE, dd MMM yyyy' | titlecase}}</p>
                            <span *ngIf="option.time">-</span>
                            <p class="m-0" *ngIf="option.time">{{option.time}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between gestion-price mb-0" *ngIf="totalDiscount">
                <p class="m-0">Descuento aplicado<span class="style-discount" *ngIf="discountObject.abono != null">:
                        {{discountObject.abono.name}}</span></p>
                <p class="m-0 style-discount">-{{totalDiscount | number: '1.2-2'}} €</p>
            </div>
            <div class="d-flex align-items-center justify-content-between gestion-price mb-0" *ngIf="!errorApi">
                <p class="m-0">Gastos de gestión</p>
                <p class="m-0">{{(gestionPrice) | number: '1.2-2'}} €</p>
            </div>
            <div class="d-flex align-items-center justify-content-between subtotal-price" *ngIf="!errorApi">
                <p class="m-0">Total <span class="gestion-price">(IVA Inc.)</span></p>
                <p class="m-0">{{(totalPrice) | number: '1.2-2'}} €</p>
            </div>


            <p (click)="openDiscountForm()" class="text-open-discount"><i
                    class="fa-light fa-{{!openFormDiscount?'chevron-down':'chevron-up'}} icon-primary"></i>¿Tienes un
                código de descuento? </p>
            <div class="discount-code container-form d-flex align-items-center" [class.hiddenForm]="!openFormDiscount"
                [class.savedCode]="savedCode">
                <input class="w-100" type="text" placeholder="Código de descuento" [(ngModel)]="discountCode"
                    [readonly]="savedCode">
                <button class="btn-discount cancel-button" [class.active]="discountCode" *ngIf="!savedCode"
                    (click)="applyDiscountCode()">Aplicar</button>
                <button *ngIf="savedCode" class="btn-discount cancel-button" [class.active]="discountCode"
                    (click)="deleteDiscount()"><i
                        class="fa-light fa-trash-can icon-primary icon-save"></i>Eliminar</button>
            </div>
            <p *ngIf="savedCode" class="info-discount"><i class="fa-light fa-circle-check icon-primary icon-save"></i>
                Descuento aplicado</p>
            <p *ngIf="errorCode && openFormDiscount" class="error-discount"><i
                    class="fa-light fa-circle-xmark icon-red icon-error"></i>
                El descuento no existe o no se ha podido aplicar</p>
            <!-- <div class="checkbox-conditions">
                <mat-checkbox [(ngModel)]="checkAcceptConditions" color="primary">Aceptar las condiciones de compra</mat-checkbox>
            </div> -->
            <button class="w-100 primary-button solid btn-finish" [class.disabled]="checkAcceptConditions" [disabled]="!loaded || waiting || checkAcceptConditions" *ngIf="!errorApi"
                (click)="openPlatformBuy()">
                Comprar
            </button>
        </div>
    </div>

    <form action="{{url}}" method="post" id="redsys_form" name="redsys_form" *ngIf="!errorApi">
        <input type="hidden" name="Ds_MerchantParameters" value="{{ Ds_MerchantParameters}}" />
        <input type="hidden" name="Ds_Signature" value="{{ Ds_Signature}}" />
        <input type="hidden" name="Ds_SignatureVersion" value="{{ Ds_SignatureVersion }}" />
        <input type="submit" name="btn_submit" id="btn_submit" value="Send" style="display: none;">
    </form>

</div>