<div class="router-event">
    <div class="info-event create-event">
        <form [formGroup]="eventInfo" class="w-100 form-create">
            <div class="text-end">
                <p class="m-0 info-required"><span class="required-field">*</span>Campos obligatorios</p>
            </div>
            <div class="d-flex flex-wrap conteiner-info-event">
                <div class="col-8">
                    <div class="col-info-category col-12">
                        <div class="category-wrapper d-flex align-items-center justify-content-between "
                            (click)="openCategories()">
                            <div class="category-select-wrapper d-flex justify-content-between align-items-center "
                                *ngIf="!categorySelected; else selectedCategory">
                                <span>Selecciona categoría</span>
                                <i class="fa-light fa-chevron-down"></i>
                            </div>
                            <ng-template #selectedCategory>
                                <div
                                    class="category-select-wrapper d-flex justify-content-between align-items-center selected">
                                    <div>
                                        <i style="color: var(--color-primary); margin-right: 10px;"
                                            [ngClass]="categorySelected.icon" class="fal"></i>
                                        {{categorySelected.nombre}}
                                    </div>
                                    <i class="fa-light fa-chevron-down"></i>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-info-event col-12 title">
                        <div class="title-wrapper ">
                            <p class="m-0 title-section-create">Título del evento<span class="required-field">*</span>
                            </p>
                            <input class="w-100" formControlName="titulo" type="text" placeholder="Título del evento"
                                name="titulo" required>
                        </div>
                    </div>
                    <div class="col-info-event col-12 description">
                        <div class="description-wrapper">
                            <p class="m-0 title-section-create">Descripción<span class="required-field">*</span></p>
                            <angular-editor #edit id="1" formControlName="descripcion" [config]="editorConfig">
                                <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                                    <ae-toolbar-set class="button-styles-text" tabindex="">
                                        <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible"
                                            [enableSearch]="false" [showPreview]="false"
                                            (emojiSelect)="executeCommandFn('insertHtml', addEmoji($event))"></emoji-mart>
                                        <button class="btn-style btn-bold"
                                            (click)="executeCommandFn('bold')"><strong>N</strong></button>
                                        <button class="btn-style btn-italic"
                                            (click)="executeCommandFn('italic')"><i>K</i></button>
                                        <button class="btn-style btn-underline"
                                            (click)="executeCommandFn('underline')"><u>S</u></button>
                                        <button class="btn-emoji pointer-in"
                                            (click)="isEmojiPickerVisible = !isEmojiPickerVisible;"><i
                                                class="fa-light fa-face-smile"></i></button>

                                    </ae-toolbar-set>
                                </ng-template>
                            </angular-editor>

                        </div>
                    </div>
                    <div class="col-info-event col-12 venue">
                        <div class="venue-wrapper">
                            <p class="m-0 title-section-create">Lugar<span class="required-field">*</span></p>
                            <!-- Select Place -->
                            <div *ngIf="!loading && placeList.length<=0;else places">
                                <div class="no-places">
                                    <p>No hay lugares disponibles</p>
                                </div>
                            </div>
                            <ng-template #places>
                                <div>
                                    <mat-form-field appearance="fill" class="filter-place" style="max-width: 350px"
                                        *ngIf="!onlineEvent && !otherAddress">
                                        <mat-select #placeSelect panelClass="selectPlaces"
                                            placeholder="Seleccionar sitio asociado a este evento"
                                            formControlName="place" (selectionChange)="changePlace($event.value)"
                                            disableOptionCentering="false">
                                            <mat-option class="option-place" *ngFor="let itemPlace of placeList"
                                                [value]="itemPlace">
                                                <div class="image-place">
                                                    <img src="{{urlImagesVenues+itemPlace.id+'/'+itemPlace.image}}"
                                                        [alt]="itemPlace.name">
                                                </div>
                                                <span class="name-place">{{itemPlace.name}}</span>
                                            </mat-option>
                                        </mat-select>
                                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                                            <i class="fal fa-angle-down pointer-in"></i>
                                        </mat-select-trigger>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                            <div class="other-address-wrapper" *ngIf="otherAddress">
                                <mat-form-field appearence="fill" class="w-100 label-title pointer-in">
                                    <input id="searchMap" #search matInput
                                        class="pointer-in bloque-change-input container-form" type="text"
                                        placeholder="Escribe la dirección del evento..." [(ngModel)]="lugar"
                                        name="location" (input)="onSearchPlaces()" [matAutocomplete]="auto"
                                        [ngModelOptions]="{standalone: true}">
                                    <mat-autocomplete (optionSelected)="onSelectPlace(search.value)"
                                        #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let option of searchResults" [value]="option"
                                            class="item-option-map">
                                            <div class="item-option">
                                                <i class="fa-solid fa-location-dot"></i>
                                                <span class="">{{option.description}}</span>
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="container-map" [class.disabled-map]="!otherAddress">
                                <div id="maplock"></div>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px; margin-top: 15px;">
                                <mat-checkbox class="example-margin" [(ngModel)]="otherAddress"
                                    [ngModelOptions]="{standalone: true}" (change)="hasOtherAddress()"> Otra
                                    dirección</mat-checkbox>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" [(ngModel)]="onlineEvent"
                                    [ngModelOptions]="{standalone: true}" (change)="isOnlineEvent()">
                                    Evento online</mat-checkbox>
                            </div>

                        </div>
                    </div>
                    <div class="col-info-event col-12 date">
                        <div class="date-wrapper">
                            <app-date-selector [fechas]="dates?.fecha" [horarios]="selectedSchedules"
                                (schedulesSelected)="getSchedules($event)" [existVenue]="eventInfo.value.place"
                                (saveDates)="getDate($event)"></app-date-selector>
                        </div>
                    </div>

                    <div class="col-info-event col-12 hashtags">
                        <div class="hashtags-wrapper">
                            <p class="title-section-create m-0">Configuración</p>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" formControlName="privateEvent">
                                    Evento privado</mat-checkbox>
                            </div>
                            <div class="title-wrapper" *ngIf="eventInfo.value.privateEvent" style="margin: 10px 0px;">
                                <input class="w-100" formControlName="accessCodeSales" type="text"
                                    placeholder="Código de acceso" name="code" required>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" formControlName="informativo"
                                    [disabled]="eventInfo.value.onlyTpvSales || eventInfo.value.external">
                                    Evento informativo</mat-checkbox>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" formControlName="onlyTpvSales"
                                    [disabled]="eventInfo.value.external || eventInfo.value.informativo">
                                    Solo venta en taquilla</mat-checkbox>
                            </div>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" formControlName="external"
                                    [disabled]="eventInfo.value.onlyTpvSales || eventInfo.value.informativo">
                                    Evento con venta externa</mat-checkbox>
                            </div>
                            <div class="title-wrapper" *ngIf="eventInfo.value.external" style="margin: 10px 0px;">
                                <input class="w-100" formControlName="url" type="text" placeholder="URL Externa"
                                    name="url" required>
                            </div>
                        </div>
                    </div>

                    <div class="col-info-event col-12 link">
                        <div class="link-wrapper ">
                            <p class="m-0 title-section-create"><i class="fa-light fa-money-bill-trend-up"></i>Facturación</p>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" formControlName="externalBilling">Facturar a nombre de otra empresa</mat-checkbox>
                            </div>
                            <div class="title-wrapper" *ngIf="eventInfo.value.externalBilling" style="margin: 10px 0px;">
                                <input class="w-100" formControlName="cifBilling" type="text" placeholder="CIF*"
                                    name="cif" required>
                            </div>
                            <div class="title-wrapper" *ngIf="eventInfo.value.externalBilling" style="margin: 10px 0px;">
                                <input class="w-100" formControlName="nameBilling" type="text" placeholder="Nombre de empresa*"
                                    name="name" required>
                            </div>
                        </div>
                    </div>

                    <div class="col-info-event col-12 link">
                        <div class="link-wrapper ">
                            <p class="m-0 title-section-create"><i class="fa-light fa-link"></i>Link asociado</p>
                            <input class="w-100" type="text" placeholder="Escribe un link asociado al evento"
                                formControlName="extraLink" name="link">
                        </div>
                    </div>

                    <div class="col-info-event col-12 link">
                        <div class="link-wrapper">
                            <p class="m-0 title-section-create"><i class="fa-light fa-image"></i>Carrusel de fotos</p>
                            <div class="container-extra-images">
                                <div class="images-wrap" *ngIf="extraImages.length > 0">
                                    <div class="content-img-extra" *ngFor="let img of extraImages; let i = index">
                                        <div class="item-img-extra">
                                            <img [src]="img.cropped" alt="Img extra" />
                                        </div>
                                        <div class="circle-delete-image" (click)="deleteExtraImg(i)">
                                            <i class="fal fa-times"></i>
                                        </div>
                                    </div>
                                </div>
                                <input id="extraImg" #extraImg hidden type="file" accept="image/*" (change)="onExtraImg($event)">
                                <div class="upload-file-style" (click)="extraImg.click()">
                                    <i class="fal fa-arrow-up-from-bracket"></i>
                                    <span>Subir fotos</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-info-event col-12 hashtags">
                        <div class="hashtags-wrapper">
                            <p class="title-section-create m-0">Visibilidad</p>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox class="example-margin" formControlName="hiddenEvent">
                                    Ocultar evento</mat-checkbox>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-info-event col-12 hashtags">
                        <div class="hashtags-wrapper">
                            <p class="title-section-create m-0"><i class="fa-light fa-hashtag"></i>Hashtags</p>
                            <app-hashtags-event [hashtags]="hashtags"
                                (saveHashtags)="saveHashtags($event)"></app-hashtags-event>
                        </div>
                    </div>
                    <!-- <div class="col-info-event col-12 youtube">
                    <div class="youtube-wrapper ">
                        <p class="m-0 title-section">Vídeo de youtube</p>
                        <input class="w-100" type="text" placeholder="Link de youtube" name="youtube">
                    </div>
                </div> -->
                    <!-- Archivo Extra -->
                    <div>
                        <p class="m-0 title-section-create" (click)="fileextra.click()"><i
                                class="fa-light fa-file"></i>Archivo descargable</p>
                        <div class="d-flex align-items-center file-upload">
                            <div class="upload-file-style" (click)="fileextra.click()">
                                <i class="fal fa-arrow-up-from-bracket"></i>
                                <span>Subir archivo</span>
                            </div>
                            <input id="fileextra" #fileextra hidden type="file" accept="*/application"
                                (change)="onExtraFile($event)">
                            <a [href]="extraFileControl" target="_blank" *ngIf="extraFileControl">Abrir archivo</a>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-image-business">
                    <div class="d-flex align-items-center justify-content-center"
                        style="gap: 10px; margin-bottom: 5px;">
                        <p class="title-image m-0">FOTO DE PORTADA<span class="required-field">*</span></p>
                        <i class="fas fa-circle-info icon-primary pointer-in"
                            matTooltip="Será la imágen que se muestre en la vista del evento."></i>
                    </div>
                    <div class="container-image" (click)="file.click()">
                        <div class="not-image-business">
                            <i class="fa-thin fa-image" *ngIf="!imgControl.value"></i>
                            <img [src]="imgControl.value" alt="Img user" *ngIf="imgControl.value" />
                        </div>
                        <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
                        <div class="circle-add-image">
                            <i class="fa-light fa-plus"></i>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="btn-create btn-next-step m-0" [routerLink]="['/panel-control/events/new-event/ticket-event']"
            (click)="nextStep()">
            <p class="m-0">Siguiente paso</p>
            <i class="fal fa-arrow-right"></i>
        </div>
    </div>
</div>